<script>
export default {
  name: "RangeSlider",
  model: {
    prop: 'modelValue',
    event: 'input'
  },
  props: {
    modelValue: {
      type: Number,
      default: 0,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      internalValue: this.modelValue,
      isDragStarted: false,
      isDragging: false,
      lastInteraction: '',
    }
  },
  computed: {
    dynamicStep() {
      if ((this.max - this.internalValue) < this.step) {
        return 1;
      }
      return this.step;
    },
    virtualLast() {
      const remainder = this.max % this.step;

      if (remainder) {
        let value = this.max - this.step;
        value -= (value % this.step);
        return value + this.min;
      }

      return this.max - this.step;
    },
    isAdjustable() {
      return this.step < Math.abs(this.max - this.min);
    }
  },
  watch: {
    internalValue() {
      this.$emit('input', this.internalValue);
    }
  },
  methods: {
    handleInteractionStart() {
      this.isDragStarted = true;
    },
    handleDrag() {
      if (!this.isDragStarted) {
        return;
      }

      this.isDragging = true;
    },
    handleInteractionEnd() {
      if (this.isDragging) {
        this.lastInteraction = 'drag';
      }
    },
    handleClick() {
      if (!(this.isDragStarted && this.isDragging)) {
        this.lastInteraction = 'click';
      }

      this.releaseDragState();
    },
    releaseDragState() {
      this.isDragging = false;
      this.isDragStarted = false;

      this.$nextTick(() => {
        this.lastInteraction = '';
      });
    },
    validate(next) {
      const prev = this.internalValue;
      const direction = prev < next ? 1 : -1;

      switch (direction) {
        case -1:
          this.decrease(next);
          break;
        case 1:
          this.increase(next);
          break;
      }
    },
    handleInput(event) {
      this.validate(event.target.valueAsNumber);
    },
    increase(next) {
      let tmp = next - (next % this.step);

      if (!this.isDragging && this.lastInteraction !== 'wheel') {
        this.setValue(next === this.virtualLast ? this.max : tmp);
        return;
      }

      const prev = this.internalValue;
      const diff = Math.abs(this.max - next);

      if (diff < this.step) {
        tmp = this.max;

        const variance = tmp - prev;
        if (this.step < variance) {
          tmp -= (this.max % this.step);
        }
      }

      this.setValue(tmp);
    },
    decrease(next) {
      let tmp = next - (next % this.step);
      const prev = this.internalValue;
      const diff = Math.abs(this.min - next);

      if (diff < this.step) {
        tmp = this.min;
      }

      const variance = prev - tmp;

      if (this.step < variance) {
        if (this.lastInteraction === 'wheel' || this.isDragging) {
          tmp += this.step;
        }
      }
      this.setValue(tmp);
    },
    handleOnWheel(event) {
      this.lastInteraction = 'wheel';

      const direction = event.deltaY < 0 ? 1 : -1;
      let value = event.target.valueAsNumber + this.step * direction;
      this.validate(value);
    },
    setValue(value) {
      if (!this.isAdjustable) {
        return;
      }
      
      this.internalValue = this.clamp(value, this.min, this.max);
    },
    clamp(value, min, max) {
      return Math.max(min, Math.min(value, max));
    },
  }
}
</script>

<template>
  <input type="range"
         :step="dynamicStep"
         :min="min"
         :max="max"
         :value="internalValue"
         @mousedown.stop="handleInteractionStart"
         @mouseup.stop="handleInteractionEnd"
         @mousemove.stop="handleDrag"
         @touchmove.stop="handleDrag"
         @touchstart.stop="handleInteractionStart"
         @touchend.stop="handleInteractionEnd"
         @wheel.stop="handleOnWheel"
         @click.stop="handleClick"
         @input.stop="handleInput"
         :disabled="!isAdjustable"
  />
</template>

<style scoped lang="scss">
input[type="range"]:disabled {
  cursor: not-allowed;
  opacity: 0.5;

  &::-webkit-slider-thumb {
    display: none;
  }

  &::-moz-range-thumb {
    display: none;
  }

  &::-ms-thumb {
    display: none;
  }
}
</style>